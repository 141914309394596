<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-16 11:35:22
 * @LastEditTime: 2019-11-14 17:34:56
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="dashboard-container">
    <page-top :title="$t('supplier_submenu.12FC12@control_panel')">
      <span class="fa fa-dashboard fa-2x"></span>
    </page-top>
    <ul class="row-box">
      <li class="block block-red">
        <section>
          <i class="fa fa-shopping-cart"></i>
          <div>
            <h5
                style="cursor:pointer;"
                @click="toUnpaid(1)"
            >{{ $t('aboutus.41856B@supplier_block1') }}</h5>
            <span style="cursor:pointer;" @click="toUnpaid(1)">{{ calc.unpaid_order_num }}</span>
          </div>
        </section>
        <img class="ball-img" src="./images/bull.png"/>
      </li>
      <li class="block block-blue">
        <section>
          <i class="fa fa-list"></i>
          <div>
            <h5
                style="cursor:pointer;"
                @click="toUnfinished(4)"
            >{{ $t('restaurant_dashboard.27D6A5@unfinish') }}</h5>
            <span style="cursor:pointer;" @click="toUnfinished(4)">{{ calc.unfinished_order_num }}</span>
          </div>
        </section>
        <img class="ball-img" src="./images/bull.png"/>
      </li>
    </ul>
    <el-card style="margin-bottom: 15px">
      <h5 slot="header">
        <i class="fa fa-bookmark"></i>
        <span style="padding-left:10px;">{{ $t('SalesRankings.title') }}</span>
      </h5>
      <el-carousel interval="1500" indicator-position="outside" height="200px" style="margin: 0 10%">
        <el-carousel-item v-for="(item, key) in topGoodsList" :key="key">
          <div style="text-align: center;margin-top: 50px">
            <h2 class="medium" style="color: #f24040;font-weight: bolder">{{ `Top ${key + 1}` }}</h2>
            <h3 style="color: #f24040;">{{ item.goods_name }}</h3>
            <h4>{{ `${$t('orderDetail.7CA68E@total_price')}：$${FormatNumber(item.total_price)}` }}</h4>
            <h4>{{ `${$t('orderDetail.7CA68E@total_price')}：${FormatNumber(item.receive_num, 3)}` }}</h4>
          </div>
        </el-carousel-item>
      </el-carousel>
      <section style="display: flex;justify-content: center;margin-top: 20px">
        <a :href="`${domain}/app/supplier/salesRankings`">{{ $t('SalesRankings.viewMore') }}</a>
      </section>
    </el-card>
    <el-card>
      <h5 slot="header">
        <i class="fa fa-bar-chart"></i>
        <span style="padding-left:10px;">{{ $t('aboutus.15D285@chart') }}</span>
      </h5>
      <el-select v-model="chartType" style="width:300px;margin-bottom:20px;">
        <el-option :value="0" :label="$t('aboutus.AF9C99@month_order_money')"></el-option>
        <el-option :value="1" :label="$t('aboutus.CD5BE2@month_money_top10')"></el-option>
        <el-option :value="2" :label="$t('aboutus.405894@month_revice_top10')"></el-option>
        <el-option :value="3" :label="$t('aboutus.29B8DC@top10_restaurant')"></el-option>
      </el-select>
      <line-chart v-if="!chartType && activeName=='0'" :xAxis="lineX" :chart-data="lineData"></line-chart>
      <bar-chart
          v-else
          :yAxis="chartType == 1?barY1:chartType == 2?barY2:barY3"
          :chart-data="chartType == 1?barData1:chartType == 2?barData2:barData3"
      ></bar-chart>
    </el-card>
  </div>
</template>

<script>
import pageTop from "@/components/PageTop/PageTop";
import BarChart from "./components/BarChart";
import lineChart from "./components/LineChart";

export default {
  name: "Dashboard",
  components: {pageTop, BarChart, lineChart},
  data() {
    return {
      topGoodsList: [],
      active: "0",
      domain: window.location.origin,
      calc: {
        unpaid_order_num: 0,
        unfinished_order_num: 0
      },
      activeName: "0",
      chartType: 0,
      lineX: [],
      lineData: [],
      barY1: [],
      barY2: [],
      barY3: [],
      barData1: [],
      barData2: [],
      barData3: [],

      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0
    };
  },
  created() {
    this.getChartData();
    this.getTopGoodsList()
  },
  methods: {
    //千分位
    FormatNumber(num, n = 2) {
      let str = num.toFixed(n).toString()
      let reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
      return str.replace(reg, "$1,")
    },
    //取得前五名產品列表
    getTopGoodsList() {
      this.$http.getTopGoodsList({
        size: 0,
        page: 0,
        start: 0,
        end: 0,
        limit: 5,
        sort_type: 1,
        sort: 2,
      }).then(res => {
        this.topGoodsList = res.data.data
      })
    },
    toUnpaid(num) {
      this.$router.push({
        path: "/app/order",
        query: {
          unpay: num
        }
      });
    },
    toUnfinished(num) {
      this.$router.push({
        path: "/app/order",
        query: {
          unfinish: num
        }
      });
    },
    getChartData() {
      this.$http.getChart({}).then(res => {
        console.log(res);
        this.calc = Object.assign({}, res.data.calc_num);

        // 当月每日下单
        this.lineX = res.data.chart.order_price_ranking.days;
        this.lineData = res.data.chart.order_price_ranking.ranking;

        // 当月买总金额前十的商品
        this.barY1 = res.data.chart.goods_price_ranking.name;
        this.barData1 = res.data.chart.goods_price_ranking.ranking;
        this.barY2 = res.data.chart.goods_receive_ranking.name;
        this.barData2 = res.data.chart.goods_receive_ranking.ranking;
        this.barY3 = res.data.chart.restaurant_ranking.name;
        this.barData3 = res.data.chart.restaurant_ranking.ranking;
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.dashboard-container {
  .row-box {
    display: flex;
    margin-bottom: 20px;

    .block {
      width: calc(50%);
      height: 220px;
      border-radius: 10px;
      margin-right: 40px;
      padding: 20px 40px 0;
      box-sizing: border-box;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      .ball-img {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
      }

      section {
        color: #fff;

        i {
          display: block;
          margin-left: auto;
          font-size: 21px;
          float: right;
        }

        h5 {
          padding-top: 21px;
          font-size: 16px;
          font-weight: 900;
        }

        span {
          font-size: 36px;
          font-weight: 900;
        }
      }
    }

    .block-red {
      background-image: linear-gradient(-90deg, #ff688a 0%, #ffbb90 100%);
    }

    .block-blue {
      background-image: linear-gradient(-90deg, #0d83e1 0%, #8ec9f8 100%);
    }

    .block-green {
      margin-right: 0;
      background-image: linear-gradient(-90deg, #1ed0b4 0%, #83d9d2 100%);
    }
  }

  .echart {
    margin-top: 40px;
  }
}
</style>
